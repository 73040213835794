"use strict";
// Type-only circular import
// eslint-disable-next-line import/no-cycle
Object.defineProperty(exports, "__esModule", { value: true });
var internalSlotMap = new WeakMap();
function getInternalSlots(x, internalSlotsList) {
    if (internalSlotsList === void 0) { internalSlotsList = []; }
    var internalSlots = internalSlotMap.get(x);
    if (!internalSlots) {
        internalSlots = Object.create(null, internalSlotsList.reduce(function (all, prop) {
            all[prop] = {
                enumerable: false,
                writable: true,
                configurable: true,
            };
            return all;
        }, {}));
        internalSlotMap.set(x, internalSlots);
    }
    return internalSlots;
}
exports.default = getInternalSlots;
